@import url("https://fonts.googleapis.com/css2?family=Geist+Mono:wght@100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:wght@100;300;400;700;900&display=swap");

html,
body {
  background: #f7f7f7;
}

body {
  width: 100dvw;
  max-width: 750px;
  min-height: 100dvh;
  margin: 0 auto;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}
