.reservation-success-container {
  position: relative;
}

h2 {
  text-align: center;
}

.qr-code-description-container {
  margin-top: 60px;
  text-align: center;
}

.qr-code-description-container > p {
  font-size: 25px;
  line-height: 54px;
  color: #888888;
}

.qr-code {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 30px;
  margin-top: 40px;
}

.booth-info-container {
  background: #ffffff;
}

.booth-text-info-container {
  position: relative;
  left: -50px;
  bottom: -30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.booth-text-info-container > p {
  text-align: center;
  z-index: 1;
}

.booth-slogan {
  color: #262727;
  font-size: 32px;
  line-height: 40px;
}

.booth-sub-slogan {
  color: #231815;
  font-size: 24px;
  line-height: 36px;
}

.booth-description {
  padding-bottom: 25px;
  color: #231815;
  font-size: 24px;
  line-height: 44px;
}

.contact-info-container {
  position: relative;
}

.contact-bg-container {
  width: 100%;
  position: relative;
}

.contact-bg-container > img {
  width: 100%;
  height: 100%;
}

.contact-info {
  font-size: 24px;
  line-height: 48px;
  text-align: center;
  position: absolute;
  top: 55%;
  left: 25%;
  transform: translateX(-50%) translateY(-50%);
}

@media screen and (max-width: 640px) {
  .contact-info {
    top: 60%;
    font-size: 14px;
    line-height: 32px;
  }

  .booth-description {
    font-size: 18px;
    line-height: 32px;
  }
}

@media screen and (max-width: 520px) {
  .booth-text-info-container {
    left: 0;
  }

  .qr-code-description-container > p {
    font-size: 17px;
    line-height: 46px;
  }

  .booth-slogan {
    font-size: 24px;
    line-height: 34px;
  }

  .booth-sub-slogan {
    font-size: 20px;
    line-height: 30px;
  }

  .booth-description {
    font-size: 16px;
    line-height: 30px;
  }
}
